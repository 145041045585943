
.footer-container{
  position:relative;

}
.footer-container>hr{
  border:1px solid var(--lightgray)

}
.footer{
  padding:1rem 2rem;
  display:flex;
flex-direction: column;
gap:4rem;
height:20rem;
align-items:center;
justify-content:center;
}
.social-links>img {
  width:2rem;
  height:2rem;
  cursor:pointer;

}
.logo-f>img{
  width:10rem;
}
.blur-f-1{
  bottom:0;
  right:15%;
  width:26rem;
  filter:blur(200px);
  background:red;

}
.blur-f-2{
 bottom:0;
 left:15%;
 width:26rem;
 height:12rem;
 background:rgb(225,115,0);
 
}